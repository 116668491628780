<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="title mb-3">{{ selectedServiceInfo.serviceName }}</div>
        <div class="department-name">
          <strong>Клиника: </strong> {{ objDepartment.name }}
        </div>
        <div class="service-name" v-if="selectedServiceInfo.doctorName">
          <strong>Доктор: </strong> {{ selectedServiceInfo.doctorName }}
        </div>
        <div class="price">
          <strong>Цена: </strong>{{ selectedServiceInfo.servicePrice }} руб.
        </div>
        <div class="city-district">
          <strong>Район города: </strong>{{ objDepartment.cityPart }}
        </div>
      </v-col>
      <v-col cols="12">
        <order-form :services="this.orderData" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as names from "store/names";
import OrderForm from "components/partials/OrderForm";

export default {
  name: "DepartmentCardServiceFast",
  components: { OrderForm },
  data: () => ({
    orderData: [],
  }),

  computed: {
    ...mapGetters({
      selectedServiceInfo: names.SELECTED_SERVICE_INFO,
      objDepartment: names.OBJ_DEPARTMENT_INFO,
    }),
  },
  mounted() {
    this.collectOrderData();
  },

  methods: {
    ...mapActions({}),

    collectOrderData() {
      this.orderData = {
        departmentName: this.objDepartment.name,

        departmentId: this.objDepartment.departmentId,

        departmentAddress: this.objDepartment.address,

        departmentPhone: this.objDepartment.phones[0].phone,

        serviceName: this.selectedServiceInfo.name,

        doctorName: this.selectedServiceInfo.doctorName,

        doctorServicePrice: null,

        servicePrice: this.selectedServiceInfo.servicePrice,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "~scss/helpers/helpers";
</style>
